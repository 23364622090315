import { connect } from 'react-redux';
import { getVehicleData } from '@autoscout24/as24-vehicle-data-local';
import {
  buttonReset, changeAndUpdateLowerFields, clearResults, fillManualLink, showFirstReg
} from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';


function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.translations[pageName].data.makeLabel,
    name: fieldNames.make,
    componentState: state.makeModelData.make,
    defaultText: state.translations[pageName].data.pleaseChoose,
    options: state.makeModelData.makes
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      if (getVehicleData() && getVehicleData().makeId && getVehicleData().fuelId) {
        try {
          localStorage.removeItem('vehicle-data');
        } catch (error) {
          console.log('Error while removing old data:', error);
        }
      }
        dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.make));
        if (e.target.value !== '0') {
          dispatch(showFirstReg());
          dispatch(fillManualLink());
        }
        dispatch(buttonReset());
        dispatch(clearResults());
    }
  };
}
const Make = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Make;
