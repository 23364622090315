import * as actionTypes from './actionTypes';
import {
  GET_VEHICLES_SUCCESS, CLEAR_VEHICLES_RESULT, SET_COLOR_PARAMETER, SET_VEHICLE_OFFER_TYPE_PARAMETER
} from '../../app/actionTypes';
import * as lookupApi from '../services/lookupApi';
import * as taxonomyApi from '../services/taxonomyFilterApi';
import * as constants from '../../common/constants';
import { dispatchManualLink } from '../common/actions';

export const changeAndUpdateLowerFields = (value, name) => {
  return {
    type: actionTypes.CHANGE_AND_UPDATE_LOWER_FIELDS,
    value,
    name
  };
};
export const fetchMakes = () => {
  return {
    type: actionTypes.MAKES_REQUEST
  };
};
export const makesSuccess = (topMakes, makes) => {
  return {
    type: actionTypes.MAKES_SUCCESS,
    topMakes,
    makes
  };
};
export const makesFailure = (error) => {
  return {
    type: actionTypes.MAKES_FAILURE,
    error
  };
};

export const fetchColors = () => {
  return {
    type: actionTypes.COLORS_REQUEST
  };
};
export const colorsSuccess = (colors) => {
  return {
    type: actionTypes.COLORS_SUCCESS,
    colors
  };
};
export const colorsFailure = (error) => {
  return {
    type: actionTypes.COLORS_FAILURE,
    error
  };
};

export const getMakesAsync = (makes, makeUrl) => {
  if (Object.keys(makes).length > 0) {
    return null;
  }
  return lookupApi.fetchMakesAsync(makeUrl);
};

export const getColorsAsync = (colors) => {
  if (Object.keys(colors).length > 0) {
    return null;
  }
  return lookupApi.fetchColorsAsync();
};

export const showFirstReg = () => {
  return {
    type: actionTypes.SHOW_FIRST_REG
  };
};
export const showFirstRegMakeModel = () => {
  return {
    type: actionTypes.SHOW_FIRST_REG_MAKE_MODEL
  };
};
export const changeFirstRegistrationAndUpdateLowerFields = (field, otherField, value, name) => {
  return {
    type: actionTypes.CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS,
    field,
    otherField,
    value,
    name
  };
};
export const getNextData = (field) => {
  return (dispatch, getState) => {
    const state = getState();
    // Do not dispatch next field after equipment because it is color
    if (state.makeModelData[field].isValid && field !== constants.fields[constants.index.equipmentLine].name) {
      return dispatch(taxonomyApi.getNextTaxonomyData(constants.fields[constants.index[field] + 1].name, state));
    }
    return null;
  };
};
export const taxonomySuccess = (data, field) => {
  return {
    type: actionTypes.TAXONOMY_SUCCESS,
    data,
    field
  };
};
export const taxonomyResultSuccess = (data, isBuildRange) => {
  return {
    type: GET_VEHICLES_SUCCESS,
    vehicles: data.versions,
    isBuildRange,
  };
};
export const taxonomyFailure = (error) => {
  return {
    type: actionTypes.TAXONOMY_FAILURE,
    error
  };
};
export const showColor = () => {
  return {
    type: actionTypes.SHOW_COLOR
  };
};
export const showEquipmentLine = () => {
  return {
    type: actionTypes.SHOW_EQUIPMENT_LINE
  };
};
export const clearResults = () => {
  return {
    type: CLEAR_VEHICLES_RESULT
  };
};
export const setColorParameter = (value) => {
  return {
    type: SET_COLOR_PARAMETER,
    value
  };
};
export const setVehicleOfferTypeParameter = (value) => {
  return {
    type: SET_VEHICLE_OFFER_TYPE_PARAMETER,
    value
  };
};
export const changeRedirectButton = (isTaxonomyFlow) => {
  return {
    type: actionTypes.BUTTON_REDIRECT,
    isTaxonomyFlow
  };
};
export const buttonReset = () => {
  return {
    type: actionTypes.BUTTON_RESET
  };
};
export const fillManualLink = () => dispatchManualLink('makeModelData');
export const getVehicleOfferTypesAsync = (vehicleOfferTypes) => {
  if (Object.keys(vehicleOfferTypes).length > 0) {
    return null;
  }
  return lookupApi.fetchVehicleOfferTypesAsync();
};
export const fetchVehicleOfferTypes = () => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_REQUEST_MAKE_MODEL
  };
};
export const vehicleOfferTypesSuccess = (vehicleOfferTypes) => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_SUCCESS_MAKE_MODEL,
    vehicleOfferTypes
  };
};
export const vehicleOfferTypesFailure = (error) => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_FAILURE_MAKE_MODEL,
    error
  };
};
export const changeVehicleOfferType = (value) => {
  return {
    type: actionTypes.CHANGE_VEHICLE_OFFER_TYPE_MAKE_MODEL,
    value
  };
};
export const changeFirstRegistration = (field, otherField, value) => ({
  type: actionTypes.FIRST_REG_CHANGE_MAKE_MODEL,
  field,
  otherField,
  value,
});
export const changeFirstRegistrationValidation = value => ({
  type: actionTypes.FIRST_REG_CHANGE_VALIDATION_MAKE_MODEL,
  value,
});
export const changeBuildRange = value => ({
  type: actionTypes.CHANGE_BUILD_RANGE,
  value,
});
export const prefill = (value) => {
  return {
    type: actionTypes.PREFILL,
    value
  };
};
